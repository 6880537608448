
.teacher-top {
    display: flex;
    flex-wrap: wrap;
    padding:10px 20px;
    border-bottom: 1px solid #eaeaea;
    .top-left {
        width: 1%;
        flex: 1;
        .left-title {
            font-size: 14px;
            line-height: 40px;
            font-weight: 500;
        }
    }
    .top-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .import-tmpl-content {
            margin: 0 10px;
            position: relative;
            .tmpl-btn {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
}
.major-screening{
    padding:10px 0 10px 20px;
    .major-screening-title{
        font-size: 14px;
        margin-right:6px;
    }
}
img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.up-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 32px;
    opacity: 0;
}
.up-img-show{
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    vertical-align: middle;
    margin-left: 10px;
}
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
    padding:10px 9px;
}
